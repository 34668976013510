// import {ReactComponent as RFQImage} from '../../../public/images/rfq.svg';

import { useEffect, useState } from "react";
import { getDashboardData } from "../../api/dashboard";
import { getLatestSubscriptions } from "../../api/subscriptions";

const tableData = [
  {
    id: 213,
    supplier: "Abdullah Sharif",
    contact: "+91 9876978889",
    category: "Education",
    submissionDate: "22-10-2022",
    closingDate: "18-11-2022",
    action: "1961",
  },
  {
    id: 214,
    supplier: "Abdullah Sharif",
    contact: "+91 9876978889",
    category: "Auto mobiles",
    submissionDate: "22-10-2022",
    closingDate: "18-11-2022",
    action: "1961",
  },
  {
    id: 215,
    supplier: "Abdullah Sharif",
    contact: "+91 9876978889",
    category: "Super Market",
    submissionDate: "22-10-2022",
    closingDate: "18-11-2022",
    action: "1961",
  },
  {
    id: 216,
    supplier: "Abdullah Sharif",
    contact: "+91 9876978889",
    category: "Electric Power Plant",
    submissionDate: "22-10-2022",
    closingDate: "18-11-2022",
    action: "1961",
  },
  {
    id: 217,
    supplier: "Abdullah Sharif",
    contact: "+91 9876978889",
    category: "Electric Power Plant",
    submissionDate: "22-10-2022",
    closingDate: "18-11-2022",
    action: "1961",
  },
];

export const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalCustomers: "16",
    totalActiveCustomers: "11",
    totalCountries: "7",
    totalRfis: "18",
    totalRfqs: "9",
    totalTemplates: "11",
  });

  const [latestSubscriptions, setLatestSubscriptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDashboardData();
      const tempSubscriptions = await getLatestSubscriptions();

      if (data) {
        setDashboardData(data);
      }
      if (tempSubscriptions) {
        setLatestSubscriptions(tempSubscriptions);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="grid gap-10 p-3 md:p-4">
      <div className="grid gap-3">
        <div className="text-header-2"> Welcome to, Admin Dashboard</div>
        <div className="grid gap-3 grid-cols-3 [&>*]:bg-primary/10 [&>*]:p-3 [&>*]:py-1">
          <div className="flex justify-between items-center rounded-md">
            <div className="grid gap-2">
              <div className="text-primary font-bold">
                {dashboardData?.totalCustomers}
              </div>
              <div className="text-xs">Total Customers</div>
            </div>
            <div>
              <img src="/images/rfq.svg" width={60} alt="rfq" />
            </div>
          </div>
          <div className="flex justify-between items-center rounded-md">
            <div className="grid gap-2">
              <div className="text-primary font-bold">
                {dashboardData?.totalActiveCustomers}
              </div>
              <div className="text-xs">Active Customers</div>
            </div>
            <div>
              <img src="/images/rfq.svg" width={60} alt="rfq" />
            </div>
          </div>
          <div className="flex justify-between items-center rounded-md">
            <div className="grid gap-2">
              <div className="text-primary font-bold">
                {dashboardData?.totalCountries}
              </div>
              <div className="text-xs">Total Countries</div>
            </div>
            <div>
              <img src="/images/rfq.svg" width={60} alt="rfq" />
            </div>
          </div>
          <div className="flex justify-between items-center rounded-md">
            <div className="grid gap-2">
              <div className="text-primary font-bold">
                {dashboardData?.totalTemplates}
              </div>
              <div className="text-xs">Total Templates</div>
            </div>
            <div>
              <img src="/images/rfq.svg" width={60} alt="rfq" />
            </div>
          </div>
          <div className="flex justify-between items-center rounded-md">
            <div className="grid gap-2">
              <div className="text-primary font-bold">
                {dashboardData?.totalRfqs}
              </div>
              <div className="text-xs">Total RFQs</div>
            </div>
            <div>
              <img src="/images/rfq.svg" width={60} alt="rfq" />
            </div>
          </div>
          <div className="flex justify-between items-center rounded-md">
            <div className="grid gap-2">
              <div className="text-primary font-bold">
                {dashboardData?.totalRfis}
              </div>
              <div className="text-xs">Total RFIs</div>
            </div>
            <div>
              <img src="/images/rfq.svg" width={60} alt="rfq" />
            </div>
          </div>
        </div>
      </div>
      <div className="grid gap-3">
        <div className="text-header-3">Latest Subscriptions</div>
        <div className="overflow-auto">
          <table className="table-auto w-full">
            <thead className="bg-gray-200 p-3 rounded-full">
              <tr className="[&>*]:p-3 [&>*]:whitespace-nowrap text-sm [&>*]:font-normal text-left">
                <th className="rounded-l-md">No.</th>
                <th>Company Name</th>
                <th>Plan Name</th>
                <th>Plan Duration Type</th>
                <th>Plan Duration</th>
                <th>Amount to Pay</th>
                <th>Order Status</th>
              </tr>
            </thead>
            <tbody className="[&>*]:border-b-[1px] border-gray-100 text-xs">
              {latestSubscriptions?.map((row, index) => (
                <tr key={index} className="[&>*]:py-4 [&>*]:px-3">
                  <td>{index + 1}</td>
                  <td>{row.customerName ?? "-"}</td>
                  <td>{row.planName ?? "-"}</td>
                  <td>
                    {row.planDurationType === "yearly"
                      ? "Yearly"
                      : "Monthly" ?? "-"}
                  </td>
                  <td>{row.planDuration ?? "-"}</td>
                  <td>{row.amountToPay ?? "-"}</td>
                  <td>
                    {row.orderStatus === "completed"
                      ? "Completed"
                      : row.orderStatus === "pending"
                      ? "Pending"
                      : row.orderStatus === "cancelled"
                      ? "Cancelled"
                      : "" ?? "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
