// closse server dev
// const baseUrl = "http://admin.closseapi.dev.zofido.com/api/v1/";

//procu server dev
// const baseUrl = "https://dev-cloudprocuadmapi.procuex.com/api/v1/";

//procu server prod
const baseUrl = "https://cloudprocuadmapi.procuex.com/api/v1/";

module.exports = { baseUrl };
