import { useEffect, useState } from "react";

import { RiAddCircleFill, RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import {
  addCategory,
  deleteCategory,
  getCategories,
  getSingleCategory,
  updateCategory,
} from "../../api/categories";
import ModalComponent from "../../features/ui/elements/ModalComponent";
import Button from "../../features/ui/elements/Button";
import TextField from "../../features/ui/elements/TextField";

export const Categories = () => {
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState({ id: null, name: "" });
  const [isModalOpen, setIsModalOpen] = useState({
    status: false,
    type: "add",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({
    status: false,
    id: null,
  });

  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await getCategories();
    setCategories(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModalChange = (type) => {
    setIsModalOpen({
      status: !isModalOpen?.status,
      type,
    });
  };

  const handleEditCategory = async (id) => {
    const data = await getSingleCategory(id);
    if (data) {
      setCategory({ id, name: data?.name });
      handleModalChange("edit");
    }
  };

  const handleSubmit = async () => {
    const data =
      isModalOpen?.type === "add"
        ? await addCategory({ name: category?.name })
        : await updateCategory(category?.id, { name: category?.name });
    if (data) {
      fetchData();
      handleModalChange(null);
    }
  };

  const handleDeleteCategory = (id) =>
    setIsDeleteModalOpen({ status: !isDeleteModalOpen?.status, id });

  const handleSubmitDelete = async () => {
    const data = await deleteCategory(isDeleteModalOpen?.id);
    if (data) {
      fetchData();
      handleDeleteCategory(null);
    }
  };

  return (
    <div className="grid gap-4 p-4">
      <div className="flex gap-2 items-center">
        <h2 className="text-2xl font-extrabold dark:text-darkModeText">
          Categories
        </h2>
        {/* <Link href="requests/new"> */}
        <RiAddCircleFill
          size={35}
          className="text-secondary-500 cursor-pointer"
          onClick={() => {
            setCategory({ id: null, name: "" });
            handleModalChange("add");
          }}
        />
        {/* </Link> */}
      </div>
      <div className="overflow-auto space-y-2">
        <table className="table-auto w-full whitespace-nowrap">
          <thead className=" p-3 rounded-full">
            <tr className="[&>*]:p-3 text-xs [&>*]:font-medium tracking-wide text-[#033155] dark:text-darkModeText">
              <th className="rounded-l-md">id</th>
              <th>Name</th>
              <th className="rounded-r-md w-ma">Action</th>
            </tr>
          </thead>
          <tbody className="[&>*]:border-b-[1px] border-gray-100 text-xs">
            {categories?.map((row, index) => (
              <tr
                key={index}
                className="[&>*]:py-4 text-center [&>*]:px-3 cursor-pointer"
                onClick={() =>
                  navigate("sub-categories", {
                    state: { id: row?.id },
                  })
                }
              >
                <td>{index + 1}</td>
                <td>{row?.name}</td>
                <td className="flex justify-center items-center gap-1">
                  <RiPencilLine
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditCategory(row?.id);
                    }}
                    className="cursor-pointer"
                    size={15}
                  />
                  <RiDeleteBinLine
                    size={16}
                    className="text-red-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(row?.id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!categories?.length && (
          <div className="grid w-full text-gray-400 italic justify-center text-xs">
            No Data Found
          </div>
        )}
      </div>
      <ModalComponent
        visibility={isModalOpen?.status}
        onChange={() => handleModalChange(null)}
      >
        <div className="flex justify-between items-center bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none">
          <div className="text-lg dark:text-darkModeText font-semibold font-sans">
            {`${isModalOpen?.type === "add" ? "Add" : "Edit"} Category`}
          </div>
          <div
            className="dark:text-darkModeText font-semibold font-sans cursor-pointer"
            onClick={() => handleModalChange(null)}
          >
            X
          </div>
        </div>
        <div className="p-1">
          <div className="grid gap-3 p-2 px-2 text-[0.7rem]">
            <div className="space-y-2">
              <TextField
                id="category"
                placeholder="Category Name"
                value={category?.name}
                onChange={(e) =>
                  setCategory({
                    ...category,
                    name: e.target.value,
                  })
                }
                name="category"
                // register={register}
                // validationRules={{
                //   required: "Name is empty",
                // }}
                // error={errors?.name}
              />
            </div>
            <div className="text-end">
              <Button type="primary" className="px-12" onClick={handleSubmit}>
                {`${isModalOpen?.type === "add" ? "Add" : "Update"}`}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        visibility={isDeleteModalOpen?.status}
        onChange={() => handleDeleteCategory(null)}
      >
        <div className="grid gap-2">
          <div className="grid gap-2">
            <div className="bg-secondary-500/10 dark:bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none rounded-md rounded-b-none">
              <div className="font-bold dark:text-darkModeText">
                Delete Category
              </div>
            </div>
            <div className="text-sm text-gray-500  p-4 ">
              Are You Sure to Delete This?
            </div>
          </div>
          <div className="flex w-full justify-end gap-3  p-2 py-2">
            <div className="w-1/6">
              <Button
                className="w-full"
                onClick={() => handleDeleteCategory(null)}
              >
                No
              </Button>
            </div>
            <div className="w-1/6">
              <Button
                className="w-full"
                type="primary"
                onClick={handleSubmitDelete}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};
